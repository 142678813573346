import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';
import LegalNav from '../components/LegalNav';
import RenderStaticHTML from '../components/RenderStaticHTML';

import warrantyContent from '../data/warranty-content.js';

const nsBase = 'page';
const ns = `${ nsBase }-legal`;

const pageContent = warrantyContent;

const Warranty = () => {
	const rootClassnames = classNames({
		[`page-warranty`]: true,
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'Warranty'} />
			<LegalNav />
			<div className={`${ ns }__page`}>
				<div className={'container-fluid'}>
					<h1 className={`${ ns }__title`}>Warranty</h1>
					<div className={`${ ns }__content`}>
						<RenderStaticHTML html={pageContent} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Warranty;
