export default `
    <p><strong>Warranty Coverage &ndash; LIMITED 1-YEAR WARRANTY</strong></p>
    <p>Violux&rsquo;s warranty obligations are limited to the terms set forth below. Violux, as defined below, warrants Violux-branded hardware products (&ldquo;Product&rdquo;) against defects in materials (including bulbs), electronics and workmanship under normal use and service to the original end-user purchaser for the Warranty Period of one year.</p>
    <p><strong>Exclusions &amp; Limitations</strong></p>
    <p>This Limited Warranty does not cover damage to any Product caused by any modifications, alterations, tampering, or improper maintenance or repairs, damage resulting from handling, storage, installation or use which is improper, damage resulting from the addition or attachment of accessories not approved by Violux, use with non-Violux products, damage caused by abuse, misuse, poor maintenance, or failure to maintain the Product, as determined by Violux in its discretion, damage resulting from normal wear and tear, accidents, Acts of God, and freight damage.This Warranty does not cover the replacement of lost or stolen Products.</p>
    <p>Improper use of electrical/ power supply, loss of power, dropped product, theft, neglect, vandalism, environmental conditions, loss of use during the period a Product is at a repair facility or otherwise awaiting parts or repair, or any other conditions whatsoever that re beyond the control of Violux</p>
    <p>This warraranty is only effective if the product is purchasesd and operated in the country in which th e product is purchased.A product that requires modifidations or adoption to enable it to operate in any other country thatn the country in which it was purchased.</p>
    <p>This warranty does not apply; (a) to damage caused by use with non-Violux products; (b) to damage caused by accident, abuse, misuse, flood, fire, earthquake or other external causes; (c) to damage caused by operating the product outside the permitted or intended uses described by Violux; (d) to damage caused by service (including upgrades and expansions) performed by anyone who is not a Violux representative; (e) to a product or part that has been modified to significantly alter functionality or capability; (f) to consumable parts, unless expressly specific and damage has occurred due to a defect in materials or workmanship.</p>
    <p>DISCLAIMER OF WARRANTIES</p>
    <p><br /> EXCEPT AS STATED ABOVE IN THIS LIMITED WARRANTY, AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VIOLUX, DISCLAIMS ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES WITH RESPECT TO THE PRODUCT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.VIOLUX ALSO LIMITS THE DURATION OF ANY IMPLIED WARRANTIES TO THE LESSER OF THE DURATION OF THIS LIMITED WARRANTY OR PERMITTED BY APPLICABLE LAW.<br /><br /> LIMITATION OF DAMAGES</p>
    <p><br /> IN ADDITION TO THE ABOVE WARRANTY DISCLAIMERS, IN NO EVENT WILL VIOLUX BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES, OR FOR ANY COSTS, ATTORNEY FEES, EXPENSES, LOSSES OR DELAYS ALLEGED TO BE AS A CONSEQUENCE OF ANY DAMAGE TO, FAILURE OF, OR DEFECT IN ANY PRODUCT INCLUDING, BUT NOT LIMITED TO, ANY CLAIMS FOR LOSS OF PROFITS ARISING FROM OR RELATING TO THIS LIMITED WARRANTY OR THE PRODUCT, AND VIOLUX&rsquo;s TOTAL CUMULATIVE LIABILITY ARISING FROM OR RELATED TO THIS LIMITED WARRANTY OR THE PRODUCT WILL NOT EXCEED THE AMOUNT ACTUALLY PAID FOR THE PRODUCT BY THE ORIGINAL PURCHASER.</p>
    <p>SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.THIS WARRANTY IS EXCLUSIVE AND IN LIEU OF ALL OTHER EXPRESS WARRANTIES, WRITTEN OR ORAL.TO THE EXTENT PERMITTED BY LAW,VIOLUX DISCLAIMS ANY IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR USE OR PURPOSE; TO THE EXTENT SUCH DISCLAIMER IS NOT PERMITTED BY LAW, SUCH IMPLIED WARRANTIES ARE LIMITED TO THE DURATION OF THE APPLICABLE EXPRESS WARRANTY AS DESCRIBED ABOVE.SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU, THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE OR JURISDICTION TO JURISDICTION.</p>
    <p>This warranty does not extend to the purchase of opened, used, repaired, repackaged, and/or resealed products, including but not limited to sale of such products on internet auction sites and/or sales of such products by surplus or bulk resellers.Any and all warranties shall immediately</p>
    <p>This warranty gives you specific legal rights and you may also have other rights which vary from state to state. The manufacturer is not responsible for any interference or damage caused by unauthorized modifications to this equipment.</p>
    <p><strong>FCC WARNING </strong></p>
    <p>This equipment has been tested and found to comply with the limits for a class B digital device, pursuant to part 15 of the FCC rules. These limits are designed to provide reasonable protection against harmful inference in a residential installation. This equipment generates, uses and can radiate radio frequency energy and, inf not installed and used in accordance with the instructions, may cause harmful interference to radio communications. However, there is no guarantee that interference will not occur in a particular installation. If this equipment does cause harmful interference to radio or television reception, which can be determined by turning the equipment off and on, the user is encouraged to try to correct the interference by one or more of the following measures; reorient or relocate the receiving antenna; increase the separation between the equipment and receiver; connect the equipment into an outlet on a circuit different from that to which the receiver is connected; consult the dealer or an experienced radio TV technician for help.</p>
    <p>Note: You are cautioned that changes or modifications not expressly approved by the party responsible for the compliance could void your authority to operate the equipment.</p>
    <p>This device complies with part 15 of the FCC rules. Operation is subject to the following two conditions; (1) this device may not cause harmful interference and (2) this device must accept any interference received, including interference that may cause undesired operation.</p>
    <p><strong>FCC Radiation Exposure Statement</strong></p>
    <p>This equipment complies with the FCC RF radiation exposure limits set forth for an uncontrolled environment. This equipment should be installed and operated with a minimum distance of 20 centimeters between the radiator and your body. This equipment complies with part 15 of the FCC rules. Operation is subject to the following two conditions; (1) this device may not cause harmful interference, (2) this device must accept any interference received including interference that may cause undesired operation.</p>
    <p><strong>Warnings</strong></p>
    <p>UV light can be damaging to the eyes and skin. This device has been designed to contain UV exposure. DO NOT attempt to open the door and look directly at the UV lamp when it is on. UV-C light is damaging to humans and animals.Do not tamper with device. Use only as directed.</p>
    <p><strong>Storage and Disposal</strong></p>
    <p>Properly dispose according to local laws and statutes.</p>
`;
